import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FiClipboard,
  FiFileText,
  FiList,
  FiLogIn,
  FiLogOut,
  FiMenu,
  FiMoon,
  FiPackage,
  FiSettings,
  FiShoppingCart,
  FiSun,
  FiWifiOff,
  FiX,
} from "react-icons/fi";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "context/AuthContext";
// import CategoryMenu from "components/navbar/CategoryMenu";
import { Link as RouterLink } from "react-router-dom";
import Search from "components/search/Search";
import { logout } from "actions/userActions";
import { useMediaQuery } from "@chakra-ui/react";

const Navbar = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userLoginInfo } = userLogin;
  const dispatch = useDispatch();
  const authPermissions = useContext(AuthContext);
  const isAdmin = authPermissions.user ? authPermissions.user.isAdmin : false;
  let displayCarts = authPermissions.user
    ? authPermissions.user.displayCarts
    : [];
  const [isMobileView] = useMediaQuery("(max-width: 48em)");
  const logoutHandler = () => {
    dispatch(logout());
  };

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleThemeToggle = () => {
    setIsDarkMode(!isDarkMode);
    toggleColorMode();
  };

  const CartOptions = () => (
    <PopoverContent maxW="xs">
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody>
        <Stack spacing={2}>
          {displayCarts.includes("purchaseOrderCart") && isAdmin && (
            <Button
              leftIcon={<FiClipboard />}
              justifyContent="flex-start"
              variant="ghost"
              size="sm"
              as="a"
              href="/admin/purchase-order-cart"
            >
              Purchase Order
            </Button>
          )}
          {displayCarts.includes("quotationCart") && isAdmin && (
            <Button
              leftIcon={<FiFileText />}
              justifyContent="flex-start"
              variant="ghost"
              size="sm"
              as="a"
              href="/admin/quotation-cart"
            >
              Quotation
            </Button>
          )}
          {/* {displayCarts.includes("quotationCart") && !isAdmin && (
            <Button
              leftIcon={<FiFileText />}
              justifyContent="flex-start"
              variant="ghost"
              size="sm"
              as="a"
              href="/quotation-cart"
            >
              Quotation
            </Button>
          )} */}
          {displayCarts.includes("offlineOrderCart") && isAdmin && (
            <Button
              leftIcon={<FiWifiOff />}
              justifyContent="flex-start"
              variant="ghost"
              size="sm"
              as="a"
              href="/admin/offline-order-cart"
            >
              Offline Order
            </Button>
          )}
          {displayCarts.includes("digitalOrderCart") && (
            <Button
              leftIcon={<FiShoppingCart />}
              justifyContent="flex-start"
              variant="ghost"
              size="sm"
              as="a"
              href="/cart"
            >
              Cart
            </Button>
          )}
        </Stack>
      </PopoverBody>
    </PopoverContent>
  );

  return (
    <Box bg={colorMode === "dark" ? "gray.800" : "white"} p="1rem">
      <Flex align="center" justify="space-between" h="4rem">
        <Flex align="center">
          <Box width={{ base: "6rem", md: "10rem" }} mr={8}>
            <RouterLink to="/">
              <Image
                src={
                  colorMode === "dark"
                    ? "/images/header/logo_color.png"
                    : "/images/header/logo_black.png"
                }
                alt="Company Logo"
                w="auto"
                h="auto"
              />
            </RouterLink>
          </Box>
          {/* {!isMobileView && (
            <Box display={{ base: "none", md: "block" }} mr={8}>
              {displayCarts.includes("quotationCart") && <CategoryMenu />}
            </Box>
          )} */}
        </Flex>
        <Box width="100%" maxW={{ base: "100%", md: "80%" }} mx="auto">
          <Search />
        </Box>
        <Box display={{ base: "block", md: "none" }}>
          <IconButton
            icon={<FiMenu />}
            variant="ghost"
            aria-label="Open Menu"
            onClick={onOpen}
          />
        </Box>

        <Flex align="center" display={{ base: "none", md: "flex" }}>
          <div>
            {userLoginInfo ? (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={
                      userLoginInfo.profile_picture !== null
                        ? userLoginInfo.profile_picture
                        : "images/blank-profile-picture.png"
                    }
                  />
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <br />
                  <Center>
                    <Avatar
                      size={"2xl"}
                      src={
                        userLoginInfo.profile_picture !== null
                          ? userLoginInfo.profile_picture
                          : "images/blank-profile-picture.png"
                      }
                    />
                  </Center>
                  <br />
                  <Center>
                    <p>{userLoginInfo.first_name}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem>
                    <RouterLink to="/profile">
                      Account Settings/Profile
                    </RouterLink>
                  </MenuItem>
                  {userLoginInfo && isAdmin && (
                    <MenuItem>
                      <RouterLink to="/admin/dashboard">
                        Admin Dashboard
                      </RouterLink>
                    </MenuItem>
                  )}
                  {/* {displayCarts.includes("quotationCart") && !isAdmin ? (
                    <MenuItem>
                      <RouterLink to="/myquotations">My Quotations</RouterLink>
                    </MenuItem>
                  ) : (
                    ""
                  )} */}
                  <MenuItem>
                    <RouterLink to="/my-orders">My Orders</RouterLink>
                  </MenuItem>
                  <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <RouterLink to="/login">Login</RouterLink>
            )}
          </div>
          {!isAdmin ? (
            <IconButton
              ml={4}
              icon={<FiShoppingCart />}
              variant="ghost"
              aria-label="Cart"
              as="a"
              href="/cart"
            />
          ) : (
            <Popover>
              <PopoverTrigger>
                <IconButton
                  ml={4}
                  icon={<FiShoppingCart />}
                  variant="ghost"
                  aria-label="Cart"
                />
              </PopoverTrigger>
              <CartOptions />
            </Popover>
          )}

          <IconButton
            size="md"
            ml={{ base: 2, md: 4 }}
            icon={isDarkMode ? <FiSun /> : <FiMoon />}
            variant="ghost"
            aria-label="Toggle Dark Mode"
            onClick={handleThemeToggle}
          />
        </Flex>
      </Flex>

      {/* Mobile Menu */}
      {isMobileView && (
        <Box
          pos="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={colorMode === "dark" ? "gray.800" : "white"}
          display={{ base: isOpen ? "block" : "none", md: "none" }}
          zIndex={99}
        >
          <Flex direction="column" h="full" p={4}>
            <Flex justify="flex-end">
              {/* <IconButton
                icon={<FiMenu />}
                variant="ghost"
                aria-label="Close Menu"
                onClick={onClose}
              /> */}
              {isOpen ? (
                <IconButton
                  icon={<FiX />}
                  variant="ghost"
                  aria-label="Close Menu"
                  onClick={onClose}
                />
              ) : (
                <IconButton
                  icon={<FiMenu />}
                  variant="ghost"
                  aria-label="Open Menu"
                  onClick={onOpen}
                />
              )}
            </Flex>

            <Stack spacing={4} mt={4}>
              {/* {displayCarts.includes("quotationCart") && <CategoryMenu />} */}
              {userLoginInfo ? (
                <>
                  <Button
                    leftIcon={<FiSettings />}
                    justifyContent="flex-start"
                    variant="ghost"
                    size="sm"
                    as="a"
                    href="/profile"
                  >
                    Account Settings
                  </Button>
                  {userLoginInfo && isAdmin && (
                    <Button
                      leftIcon={<FiPackage />}
                      justifyContent="flex-start"
                      variant="ghost"
                      size="sm"
                      as="a"
                      href="/admin/dashboard"
                    >
                      Admin Dashboard
                    </Button>
                  )}
                  {/* {displayCarts.includes("quotationCart") && !isAdmin ? (
                    <Button
                      leftIcon={<FiFileText />}
                      justifyContent="flex-start"
                      variant="ghost"
                      size="sm"
                      as="a"
                      href="/myquotations"
                    >
                      My Quotation
                    </Button>
                  ) : (
                    ""
                  )} */}
                  <Button
                    leftIcon={<FiPackage />}
                    justifyContent="flex-start"
                    variant="ghost"
                    size="sm"
                    as="a"
                    href="/my-orders"
                  >
                    My Orders
                  </Button>
                </>
              ) : (
                <Button
                  leftIcon={<FiLogIn />}
                  justifyContent="flex-start"
                  variant="ghost"
                  size="sm"
                  as="a"
                  href="/login"
                >
                  Login
                </Button>
              )}
            </Stack>

            <Stack spacing={4} mt={4}>
              {displayCarts.includes("purchaseOrderCart") && isAdmin && (
                <Button
                  leftIcon={<FiClipboard />}
                  justifyContent="flex-start"
                  variant="ghost"
                  size="sm"
                  as="a"
                  href="/admin/purchase-order-cart"
                >
                  Purchase Order
                </Button>
              )}
              {displayCarts.includes("quotationCart") && isAdmin && (
                <Button
                  leftIcon={<FiFileText />}
                  justifyContent="flex-start"
                  variant="ghost"
                  size="sm"
                  as="a"
                  href="/admin/quotation-cart"
                >
                  Quotation
                </Button>
              )}
              {/* {displayCarts.includes("quotationCart") && !isAdmin && (
                <Button
                  leftIcon={<FiFileText />}
                  justifyContent="flex-start"
                  variant="ghost"
                  size="sm"
                  as="a"
                  href="/quotation-cart"
                >
                  Quotation
                </Button>
              )} */}
              {displayCarts.includes("offlineOrderCart") && isAdmin && (
                <Button
                  leftIcon={<FiWifiOff />}
                  justifyContent="flex-start"
                  variant="ghost"
                  size="sm"
                  as="a"
                  href="/admin/offline-order-cart"
                >
                  Offline Order
                </Button>
              )}
              {displayCarts.includes("digitalOrderCart") && (
                <Button
                  leftIcon={<FiShoppingCart />}
                  justifyContent="flex-start"
                  variant="ghost"
                  size="sm"
                  as="a"
                  href="/cart"
                >
                  Cart
                </Button>
              )}
              {/* Render your additional cart options here */}
            </Stack>

            <Stack spacing={4} mt={4}>
              {userLoginInfo ? (
                <>
                  <Button
                    leftIcon={<FiLogOut />}
                    justifyContent="flex-start"
                    variant="ghost"
                    size="sm"
                    onClick={logoutHandler}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                ""
              )}
            </Stack>
            <Stack spacing={4} mt={4}>
              <Button
                leftIcon={isDarkMode ? <FiSun /> : <FiMoon />}
                justifyContent="flex-start"
                variant="ghost"
                size="sm"
                onClick={handleThemeToggle}
              >
                Toggle Dark Mode
              </Button>
            </Stack>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default Navbar;
